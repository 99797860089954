'use client'

import Dialog from '@/components/dialog'
import { cls, getLocalStorage, setLocalStorage, utcDate } from '@/utils'
import useAmplitude from '@/hooks/useAmplitude'
import { useCallback, useMemo } from 'react'
import UpgradeButton from '@/components/upgrade-button'
import useActivePlan from '@/hooks/useActivePlan'
import dayjs, { Dayjs } from 'dayjs'
import { atom, useAtom } from 'jotai'
import IconUnLimited from '@haiper/icons-svg/icons/outline/unlimited.svg'
import IconIcons from '@haiper/icons-svg/icons/outline/icons.svg'
import IconLogin from '@haiper/icons-svg/icons/outline/login.svg'
import IconPlaceholder from '@haiper/icons-svg/icons/outline/placeholder.svg'
import IconCheckRadio from '@haiper/icons-svg/icons/outline/check-radio.svg'
import IconEyeOff from '@haiper/icons-svg/icons/outline/eye-off.svg'
import { usePathname } from 'next/navigation'
import useAuth0Auth from '@/hooks/useAuth0Auth'
import { CACHE_KEY_SUBSCRIPTION_REMIND } from '@/constants'

const initRemindTime = () => {
  try {
    const lastTime = getLocalStorage(CACHE_KEY_SUBSCRIPTION_REMIND, {
      raw: true,
    })
    if (lastTime) {
      const obj = dayjs(lastTime)
      if (obj.isValid()) {
        return obj
      }
    }
  } catch (error) {
    return null
  }
  return null
}

const lastSubscriptionRemindTimeAtom = atom<Dayjs | null>(initRemindTime())

const features = [
  { icon: IconUnLimited, text: 'Unlimited basic video creations (beta)' },
  { icon: IconIcons, text: 'More concurrent creations' },
  { icon: IconLogin, text: 'Early access to new features' },
  { icon: IconPlaceholder, text: 'Create watermark-free videos' },
  { icon: IconCheckRadio, text: 'Commercial use' },
  { icon: IconEyeOff, text: 'Private creation' },
]

export function SubscriptionRemind() {
  const { track } = useAmplitude()
  const [lastSubscriptionRemindTime, setLastSubscriptionRemindTime] = useAtom(lastSubscriptionRemindTimeAtom)
  const { data: myPlan, loading } = useActivePlan()
  const pathname = usePathname()

  const { isLogin } = useAuth0Auth()

  const shouldShow = useMemo(() => {
    return !!(
      !loading &&
      (myPlan?.is_free || !myPlan) &&
      (!lastSubscriptionRemindTime?.isValid() || lastSubscriptionRemindTime.isBefore(dayjs().subtract(7, 'day'))) &&
      ['/', '/explore', '/creations'].includes(pathname) &&
      dayjs(utcDate()).isBefore(dayjs('2024-06-30')) &&
      isLogin
    )
  }, [loading, myPlan, lastSubscriptionRemindTime, pathname, isLogin])

  const handleClose = useCallback(() => {
    const now = dayjs()
    setLastSubscriptionRemindTime(now)
    track('click:membership:subscription-remind-close')
    setLocalStorage(CACHE_KEY_SUBSCRIPTION_REMIND, now.toISOString(), {
      raw: true,
    })
  }, [track, setLastSubscriptionRemindTime])

  const handleClick = useCallback(() => {
    // hide dialog but do not save to localStorage
    setLastSubscriptionRemindTime(dayjs())
  }, [setLastSubscriptionRemindTime])

  return (
    <Dialog
      open={shouldShow}
      title={null}
      titleClassName=''
      className={cls('p-0 w-max md:w-max min-w-max border-0')}
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='relative w-[343px] md:w-[578px] flex h-[424px]'>
        <img
          className='hidden md:block'
          src='https://scontent.haiper.ai/webapp/images/subscription/subscription-remind-bg.png'
          width={250}
          alt='background'
        />
        <div className='p-6 w-full md:w-[328px] min-w-[328px] flex flex-col'>
          <div className='text-heading-2xl font-bold leading-[normal]' aria-label='title'>
            Introducing
            <br />
            Haiper Membership
          </div>
          <div aria-label='features' className='flex flex-col mt-6 mb-8 flex-1 gap-4'>
            {features.map((feature) => {
              return (
                <div key={feature.text} className='flex gap-2 items-center'>
                  <feature.icon className='size-6 text-text-interactive' />
                  <div className='text-body-md tracking-15'>{feature.text}</div>
                </div>
              )
            })}
          </div>
          <UpgradeButton source='subscription-remind-dialog' className='w-full h-10' onClick={handleClick} />
        </div>
      </div>
    </Dialog>
  )
}
